import React from "react";

interface LinkDetailsProps {
  url: string;
}

export default function LinkDetails({ url }: LinkDetailsProps) {
  const { hostname, pathname } = new URL(url);

  const cleanHostname = hostname.startsWith("www.") ? hostname.slice(4) : hostname;
  const displayUrl = cleanHostname + pathname;

  return (
    <div className="flex items-center gap-2 text-sm">
      <img src="/linkIcon.svg" alt="Link icon" width="12" height="14" />
      <span className="cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap font-semibold">
        {displayUrl}
      </span>
    </div>
  );
}
