import { useState, useEffect } from "react";
import { fetchChatInfo, fetchChatMessages, sendChatMessage } from "../services/chatService";
import { Chat } from "../../../apiClient/generated/models/Chat";
import { Message } from "../../../apiClient/generated/models/Message";
import { CreateMessageDto } from "../../../apiClient/generated/models/CreateMessageDto";

// const PAGE_SIZE = 20; // Define the number of messages per page

const sortMessagesByDate = (messages: Message[]): Message[] => {
  return [...messages].sort((a, b) => a.time.getTime() - b.time.getTime());
};

export const useChat = (chatId: string) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [chatInfo, setChatInfo] = useState<Chat | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1); //eslint-disable-line
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    // Reset states when chatId changes
    setMessages([]);
    setChatInfo(null);
    setError(null);
    setIsLoading(true);
    setPage(1);
    setHasMore(true);

    let isMounted = true;

    const loadChatInfo = async () => {
      try {
        const chatInfo = await fetchChatInfo(chatId);
        if (isMounted) {
          setChatInfo(chatInfo);
        }
      } catch (err) {
        if (isMounted) {
          setError("Failed to load chat info");
        }
      }
    };

    const loadMessages = async () => {
      try {
        const messagesResponse = await fetchChatMessages(chatId);
        if (isMounted) {
          if (messagesResponse.length) {
            setHasMore(false);
          }
          setMessages(sortMessagesByDate(messagesResponse));
        }
      } catch (err) {
        if (isMounted) {
          setError("Failed to load messages");
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    loadChatInfo();
    loadMessages();

    return () => {
      isMounted = false;
    };
  }, [chatId]); // Only depend on chatId

  const loadMoreMessages = () => {
    if (!hasMore || isLoading) return;
    setPage((prevPage) => prevPage + 1);
  };

  const addMessage = async (messageDto: CreateMessageDto) => {
    try {
      const newMessage = await sendChatMessage(chatId, messageDto);
      setMessages((prevMessages) => sortMessagesByDate([...prevMessages, newMessage]));
    } catch (err) {
      setError("Failed to send message");
    }
  };

  const deleteMessage = (id: string) => {
    setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== id));
  };

  return {
    messages,
    chatInfo,
    error,
    isLoading,
    addMessage,
    deleteMessage,
    loadMoreMessages,
    hasMore,
  };
};
