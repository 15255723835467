import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaGoogle, FaMicrosoft } from "react-icons/fa";
import { AuthApi, LoginCodeDto } from "../../../apiClient/generated";
import { getApiConfig } from "../../../apiClient/config";
import { useAuth } from "../AuthProvider";

interface EmailClientButton {
  icon: typeof FaGoogle | typeof FaMicrosoft;
  label: string;
  url: string;
  color: string;
}

const emailClients: EmailClientButton[] = [
  {
    icon: FaGoogle,
    label: "Open Gmail",
    url: "https://gmail.com",
    color: "text-red-500",
  },
  {
    icon: FaMicrosoft,
    label: "Open Outlook",
    url: "https://outlook.live.com",
    color: "text-blue-500",
  },
];

const LoginCode: React.FC = () => {
  const [loginCode, setLoginCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  const handleEmailClientClick = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    const codeToken = location.state?.codeToken;

    if (!codeToken) {
      setError("Code token is missing. Please try logging in again.");
      setIsLoading(false);
      return;
    }

    try {
      const authApi = new AuthApi(getApiConfig());
      const loginCodeDto: LoginCodeDto = { loginCode, codeToken };
      const rawResponse = await authApi.loginWithCodeRaw({ loginCodeDto });
      const responseData = await rawResponse.raw.json();

      if (!responseData.accessToken) {
        throw new Error("No access token received");
      }

      login(responseData.accessToken);
      navigate("/", { replace: true });
    } catch (err) {
      console.error("Login Error:", err);
      setError(err instanceof Error ? err.message : "Invalid login code");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Enter Login Code
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Check your email for the login code
          </p>
        </div>

        <div className="flex justify-center space-x-4">
          {emailClients.map(({ icon: Icon, label, url, color }) => (
            <button
              key={url}
              onClick={() => handleEmailClientClick(url)}
              className="flex items-center space-x-2 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm transition-colors hover:bg-gray-50"
            >
              <Icon className={`h-5 w-5 ${color}`} />
              <span>{label}</span>
            </button>
          ))}
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="login-code" className="sr-only">
                Login Code
              </label>
              <input
                id="login-code"
                name="loginCode"
                type="text"
                required
                maxLength={6}
                autoComplete="off"
                value={loginCode}
                onChange={(e) => setLoginCode(e.target.value)}
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="6-digit login code"
              />
            </div>
          </div>

          {error && (
            <div className="text-center text-sm text-red-600" role="alert">
              {error}
            </div>
          )}

          <button
            type="submit"
            disabled={isLoading}
            className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-400"
          >
            {isLoading ? "Verifying..." : "Verify Code"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginCode;
