import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { formatDate } from "../../../../../utils/dateFormatting";

interface StickyDateHeaderProps {
  date: Date | null;
  isVisible: boolean;
}

const StickyDateHeader: React.FC<StickyDateHeaderProps> = ({ date, isVisible }) => {
  if (!date) return null;

  return (
    <AnimatePresence>
      {isVisible && (
        <div className="pointer-events-none absolute left-0 right-0 top-4 z-10">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            <div className="flex items-center justify-center">
              <div className="rounded-full bg-white px-4 py-1 text-sm text-gray-500 shadow-sm">
                {formatDate(date)}
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default StickyDateHeader;
