import { getToken } from "../utils/auth";
import { Configuration } from "./generated";
import { config } from "../config";

export const getApiConfig = () => {
  const authToken = getToken();
  return new Configuration({
    basePath: config.api.basePath,
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : "",
    },
  });
};
