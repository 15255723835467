import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaDownload, FaSearchPlus, FaSearchMinus, FaUndo, FaTimes } from "react-icons/fa";

interface ImageModalProps {
  imageUrl: string;
  alt: string;
  onClose: () => void;
  senderName: string;
  senderIcon: string;
}

const ImageModal: React.FC<ImageModalProps> = ({
  imageUrl,
  alt,
  onClose,
  senderName,
  senderIcon,
}) => {
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "image.jpg"; //TODO: Change to a dynamic image name based on the image file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleZoomIn = () => setZoom((prev) => Math.min(prev + 0.1, 3));
  const handleZoomOut = () => setZoom((prev) => Math.max(prev - 0.1, 0.5));
  const handleRotate = () => setRotation((prev) => (prev + 90) % 360);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex flex-col overflow-hidden bg-black bg-opacity-30 backdrop-blur-md"
      >
        <div className="z-20 flex items-center justify-between p-4">
          <div className="flex items-center">
            <img src={senderIcon} alt={senderName} className="mr-3 h-8 w-8 rounded-full" />
            <span className="text-lg font-semibold text-white">{senderName}</span>
          </div>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={onClose}
            className="rounded-full bg-white bg-opacity-20 p-2 text-white hover:bg-opacity-30"
          >
            <FaTimes size={20} />
          </motion.button>
        </div>

        <div className="z-10 flex flex-grow items-center justify-center p-2" onClick={onClose}>
          {isLoading && (
            <div className="absolute flex h-full w-full items-center justify-center bg-black/5 backdrop-blur-sm">
              <div className="flex flex-col items-center gap-4">
                <div className="h-12 w-12 animate-spin rounded-full border-4 border-gray-200 border-t-blue-500" />
                <span className="text-sm font-medium text-gray-200">Loading image...</span>
              </div>
            </div>
          )}
          <motion.img
            src={`${imageUrl}?size=original`}
            alt={alt}
            className={`max-h-[calc(100vh-200px)] max-w-[90vw] object-contain ${
              isLoading ? "hidden" : ""
            }`}
            style={{
              transform: `scale(${zoom}) rotate(${rotation}deg)`,
              transition: "transform 0.3s ease",
            }}
            onClick={(e) => e.stopPropagation()}
            onLoad={handleImageLoad}
          />
        </div>

        <div className="z-20 flex justify-center space-x-4 p-4">
          {[
            { icon: FaDownload, action: handleDownload, label: "Download" },
            { icon: FaSearchPlus, action: handleZoomIn, label: "Zoom In" },
            { icon: FaSearchMinus, action: handleZoomOut, label: "Zoom Out" },
            { icon: FaUndo, action: handleRotate, label: "Rotate" },
          ].map(({ icon: Icon, action, label }, index) => (
            <motion.button
              key={index}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={action}
              className="flex items-center rounded-full bg-white bg-opacity-20 p-2 text-white hover:bg-opacity-30 sm:px-4 sm:py-2"
            >
              <Icon size={16} className="sm:mr-2" />
              <span className="hidden text-sm sm:inline">{label}</span>
            </motion.button>
          ))}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ImageModal;
