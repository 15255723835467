/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Chat,
  CreateMessageDto,
  Message,
} from '../models/index';
import {
    ChatFromJSON,
    ChatToJSON,
    CreateMessageDtoFromJSON,
    CreateMessageDtoToJSON,
    MessageFromJSON,
    MessageToJSON,
} from '../models/index';

export interface GetChatRequest {
    id: string;
}

export interface GetMessagesRequest {
    id: string;
}

export interface SendMessageRequest {
    id: string;
    createMessageDto: CreateMessageDto;
}

/**
 * 
 */
export class ChatsApi extends runtime.BaseAPI {

    /**
     * Get chat information
     */
    async getChatRaw(requestParameters: GetChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Chat>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getChat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/chats/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatFromJSON(jsonValue));
    }

    /**
     * Get chat information
     */
    async getChat(requestParameters: GetChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Chat> {
        const response = await this.getChatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get messages of a chat
     */
    async getMessagesRaw(requestParameters: GetMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Message>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getMessages().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/chats/{id}/messages`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MessageFromJSON));
    }

    /**
     * Get messages of a chat
     */
    async getMessages(requestParameters: GetMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Message>> {
        const response = await this.getMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send new message to a chat
     */
    async sendMessageRaw(requestParameters: SendMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Message>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling sendMessage().'
            );
        }

        if (requestParameters['createMessageDto'] == null) {
            throw new runtime.RequiredError(
                'createMessageDto',
                'Required parameter "createMessageDto" was null or undefined when calling sendMessage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/chats/{id}/messages`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMessageDtoToJSON(requestParameters['createMessageDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageFromJSON(jsonValue));
    }

    /**
     * Send new message to a chat
     */
    async sendMessage(requestParameters: SendMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Message> {
        const response = await this.sendMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
