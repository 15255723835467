import { useRef, useEffect } from "react";
import { CreateMessageDto } from "../../../../../apiClient/generated/models/CreateMessageDto";
import { ItemDto } from "../../../../../apiClient/generated/models/ItemDto";
import { useFileUpload } from "./useFileUpload";
import { useAutoResizeTextArea } from "./useAutoResizeTextArea";
import { processMessageUrls } from "../../../../../utils/urlUtils";

interface UseInputAreaProps {
  onSendMessage: (messageDto: CreateMessageDto) => void;
  onHeightChange: (height: number) => void;
}

export const useInputArea = ({ onSendMessage, onHeightChange }: UseInputAreaProps) => {
  const { message, textareaRef, handleMessageChange, textareaHeight } = useAutoResizeTextArea();
  const { files, handleFileSelect, handleRemoveFile, setFiles } = useFileUpload();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const previousTotalHeightRef = useRef(0);

  useEffect(() => {
    const updateScrollAndHeight = () => {
      const scrollableDiv = document.getElementById("scrollableDiv");
      const totalHeight = textareaHeight + (files.length > 0 ? 96 : 0);

      if (scrollableDiv && previousTotalHeightRef.current !== totalHeight) {
        const heightDiff = totalHeight - previousTotalHeightRef.current;
        scrollableDiv.scrollTop = scrollableDiv.scrollTop + heightDiff;
      }

      previousTotalHeightRef.current = totalHeight;
      onHeightChange(totalHeight);
    };

    updateScrollAndHeight();
  }, [textareaHeight, files.length, onHeightChange]);

  const createMessageDto = async (
    message: string,
    uploadedItems: ItemDto[],
  ): Promise<CreateMessageDto> => {
    const urlIds = message.trim() ? await processMessageUrls(message.trim()) : [];
    const urlItems: ItemDto[] = urlIds.map((id) => ({
      type: "url",
      id: id,
    }));

    return {
      body: message,
      items: [...uploadedItems, ...urlItems],
    };
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const currentMessage = message;
    const currentFiles = files
      .filter((f) => f.status === "uploaded")
      .map((f) => f.uploadedItem) as ItemDto[];

    if (currentMessage.trim() || currentFiles.length > 0) {
      // Clear the UI immediately
      handleMessageChange("");
      setFiles([]);

      try {
        const messageDto = await createMessageDto(currentMessage, currentFiles);
        await onSendMessage(messageDto);
      } catch (error) {
        console.error("Error processing message:", error);
        // TODO: Implement error handling - potentially show a toast notification
        // and optionally restore the message if sending failed
      }
    }
  };

  return {
    message,
    files,
    textareaRef,
    fileInputRef,
    textareaHeight,
    handleMessageChange,
    handleFileSelect,
    handleRemoveFile,
    handleSubmit,
  };
};
