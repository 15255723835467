/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ItemDto } from './ItemDto';
import {
    ItemDtoFromJSON,
    ItemDtoFromJSONTyped,
    ItemDtoToJSON,
    ItemDtoToJSONTyped,
} from './ItemDto';

/**
 * 
 * @export
 * @interface CreateMessageDto
 */
export interface CreateMessageDto {
    /**
     * Message body text. Optional if items property is used.
     * @type {string}
     * @memberof CreateMessageDto
     */
    body?: string;
    /**
     * Items attached to this message. Can be shared images, documents, user profiles, etc.
     * @type {Array<ItemDto>}
     * @memberof CreateMessageDto
     */
    items?: Array<ItemDto>;
}

/**
 * Check if a given object implements the CreateMessageDto interface.
 */
export function instanceOfCreateMessageDto(value: object): value is CreateMessageDto {
    return true;
}

export function CreateMessageDtoFromJSON(json: any): CreateMessageDto {
    return CreateMessageDtoFromJSONTyped(json, false);
}

export function CreateMessageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMessageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'body': json['body'] == null ? undefined : json['body'],
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(ItemDtoFromJSON)),
    };
}

  export function CreateMessageDtoToJSON(json: any): CreateMessageDto {
      return CreateMessageDtoToJSONTyped(json, false);
  }

  export function CreateMessageDtoToJSONTyped(value?: CreateMessageDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'body': value['body'],
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(ItemDtoToJSON)),
    };
}

