import { useState, useEffect } from "react";
import { FileStatus, FileType } from "../types";
import { uploadMultipleFiles } from "../../../services/fileUploadService";

export const useFileUpload = () => {
  const [files, setFiles] = useState<FileStatus[]>([]);

  const getFileType = (file: File): FileType => {
    if (file.type.startsWith("image/")) return "image";
    if (file.type.startsWith("audio/")) return "recording";
    return "document";
  };

  const createLocalPreview = (file: File): string | undefined => {
    if (file.type.startsWith("image/")) {
      return URL.createObjectURL(file);
    }
    return undefined;
  };

  const handleFileSelect = async (selectedFiles: FileList | null) => {
    if (!selectedFiles?.length) return;

    const fileArray = Array.from(selectedFiles);
    const newFileStatuses: FileStatus[] = fileArray.map((file) => ({
      id: crypto.randomUUID(),
      file,
      status: "uploading",
      localPreview: createLocalPreview(file),
      fileType: getFileType(file),
    }));

    setFiles((prev) => [...prev, ...newFileStatuses]);

    try {
      const uploadedFiles = await uploadMultipleFiles(fileArray);

      setFiles((prev) =>
        prev.map((status) => {
          const uploadedFile = uploadedFiles.find((uf) => uf.file === status.file);
          if (uploadedFile) {
            const { result } = uploadedFile;
            return {
              ...status,
              status: "uploaded",
              uploadedUrl: result.imageUrl,
              uploadedItem: {
                id: result.id,
                type: status.fileType,
                itemType: status.fileType,
                name: result.name,
                description: result.description,
                identifier: result.identifier,
              },
            };
          }
          return { ...status, status: "error" };
        }),
      );
    } catch (error) {
      setFiles((prev) =>
        prev.map((status) => {
          if (status.status !== "uploaded") {
            return { ...status, status: "error" };
          }
          return status;
        }),
      );
    }
  };

  const handleRemoveFile = (fileId: string) => {
    setFiles((prev) => {
      const fileToRemove = prev.find((f) => f.id === fileId);
      if (fileToRemove?.localPreview) {
        URL.revokeObjectURL(fileToRemove.localPreview);
      }
      return prev.filter((f) => f.id !== fileId);
    });
  };

  useEffect(() => {
    return () => {
      files.forEach((file) => {
        if (file.localPreview) {
          URL.revokeObjectURL(file.localPreview);
        }
      });
    };
  }, []);

  return {
    files,
    setFiles,
    handleFileSelect,
    handleRemoveFile,
  };
};
