import { CreateUrlDto } from "../apiClient/generated/models/CreateUrlDto";
import { UrlsApi } from "../apiClient/generated/apis/UrlsApi";
import { getApiConfig } from "../apiClient/config";

const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;

export const extractUrls = (text: string): string[] => {
  return text.match(URL_REGEX) || [];
};

const getUrlsApi = () => {
  const config = getApiConfig();
  return new UrlsApi(config);
};

export const processUrl = async (url: string): Promise<string> => {
  try {
    const urlDto: CreateUrlDto = {
      name: "", // Placeholder name
      url: url,
      description: "",
      imageIdentifier: "",
      expires: new Date(Date.now() + 2 * 365 * 24 * 60 * 60 * 1000), // Expires in 2 years
    };

    const urlsApi = getUrlsApi();
    const response = await urlsApi.createUrl({ createUrlDto: urlDto });

    return response.id;
  } catch (error) {
    console.error("Error processing URL:", error);
    throw error;
  }
};

export const processMessageUrls = async (messageBody: string): Promise<string[]> => {
  const urls = extractUrls(messageBody);
  if (urls.length === 0) return [];

  try {
    const identifiers = await Promise.all(urls.map((url) => processUrl(url)));
    return identifiers;
  } catch (error) {
    console.error("Error processing message URLs:", error);
    throw error;
  }
};
