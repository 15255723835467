import { ChatsApi } from "../../../apiClient/generated/apis";
import { getApiConfig } from "../../../apiClient/config";
import { Message } from "../../../apiClient/generated/models/Message";
import { CreateMessageDto } from "../../../apiClient/generated/models/CreateMessageDto";

const getChatsApi = () => {
  const config = getApiConfig();
  return new ChatsApi(config);
};

export const fetchChatInfo = async (chatId: string) => {
  try {
    const chatInfo = await getChatsApi().getChat({ id: chatId });
    return chatInfo;
  } catch (error) {
    console.error("Error fetching chat info:", error);
    throw error;
  }
};

export const fetchChatMessages = async (chatId: string): Promise<Message[]> => {
  try {
    const messages = await getChatsApi().getMessages({ id: chatId });
    return messages;
  } catch (error) {
    console.error("Error fetching chat messages:", error);
    throw error;
  }
};

export const sendChatMessage = async (
  chatId: string,
  messageDto: CreateMessageDto,
): Promise<Message> => {
  try {
    const chatsApi = getChatsApi();
    const message = await chatsApi.sendMessage({
      id: chatId,
      createMessageDto: messageDto,
    });

    return message;
  } catch (error) {
    console.error("Detailed error:", error);
    throw error;
  }
};
