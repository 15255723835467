import { useState, useEffect, useCallback } from "react";

export const useScrollToBottom = (scrollableId: string) => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const isAtBottom = useCallback(() => {
    const element = document.getElementById(scrollableId);
    if (!element) return true;

    const threshold = 100; // Show button when user is 100px away from bottom
    return element.scrollHeight - element.scrollTop - element.clientHeight < threshold;
  }, [scrollableId]);

  const scrollToBottom = useCallback(() => {
    const element = document.getElementById(scrollableId);
    if (!element) return;

    element.scrollTo({
      top: element.scrollHeight,
      behavior: "smooth",
    });
  }, [scrollableId]);

  useEffect(() => {
    const element = document.getElementById(scrollableId);
    if (!element) return;

    const handleScroll = () => {
      setShowScrollButton(!isAtBottom());
    };

    element.addEventListener("scroll", handleScroll);
    return () => element.removeEventListener("scroll", handleScroll);
  }, [scrollableId, isAtBottom]);

  return {
    showScrollButton,
    scrollToBottom,
  };
};
