/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateUrlDto,
  Url,
} from '../models/index';
import {
    CreateUrlDtoFromJSON,
    CreateUrlDtoToJSON,
    UrlFromJSON,
    UrlToJSON,
} from '../models/index';

export interface CreateUrlRequest {
    createUrlDto: CreateUrlDto;
}

export interface GetUrlRequest {
    identifier: string;
}

export interface RedirectUrlRequest {
    identifier: string;
}

/**
 * 
 */
export class UrlsApi extends runtime.BaseAPI {

    /**
     * Create a new URL item
     */
    async createUrlRaw(requestParameters: CreateUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Url>> {
        if (requestParameters['createUrlDto'] == null) {
            throw new runtime.RequiredError(
                'createUrlDto',
                'Required parameter "createUrlDto" was null or undefined when calling createUrl().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/urls`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUrlDtoToJSON(requestParameters['createUrlDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UrlFromJSON(jsonValue));
    }

    /**
     * Create a new URL item
     */
    async createUrl(requestParameters: CreateUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Url> {
        const response = await this.createUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get URL item
     */
    async getUrlRaw(requestParameters: GetUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Url>> {
        if (requestParameters['identifier'] == null) {
            throw new runtime.RequiredError(
                'identifier',
                'Required parameter "identifier" was null or undefined when calling getUrl().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/urls/{identifier}`.replace(`{${"identifier"}}`, encodeURIComponent(String(requestParameters['identifier']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UrlFromJSON(jsonValue));
    }

    /**
     * Get URL item
     */
    async getUrl(requestParameters: GetUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Url> {
        const response = await this.getUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Redirect browser to the URL.
     */
    async redirectUrlRaw(requestParameters: RedirectUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['identifier'] == null) {
            throw new runtime.RequiredError(
                'identifier',
                'Required parameter "identifier" was null or undefined when calling redirectUrl().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/urls/{identifier}/redirect`.replace(`{${"identifier"}}`, encodeURIComponent(String(requestParameters['identifier']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Redirect browser to the URL.
     */
    async redirectUrl(requestParameters: RedirectUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.redirectUrlRaw(requestParameters, initOverrides);
    }

}
