/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Url
 */
export interface Url {
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof Url
     */
    shortIdentifier: string;
    /**
     * 
     * @type {Date}
     * @memberof Url
     */
    expires: Date;
}

/**
 * Check if a given object implements the Url interface.
 */
export function instanceOfUrl(value: object): value is Url {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    if (!('identifier' in value) || value['identifier'] === undefined) return false;
    if (!('shortIdentifier' in value) || value['shortIdentifier'] === undefined) return false;
    if (!('expires' in value) || value['expires'] === undefined) return false;
    return true;
}

export function UrlFromJSON(json: any): Url {
    return UrlFromJSONTyped(json, false);
}

export function UrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): Url {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'imageUrl': json['imageUrl'] == null ? undefined : json['imageUrl'],
        'name': json['name'],
        'description': json['description'],
        'url': json['url'],
        'identifier': json['identifier'],
        'shortIdentifier': json['shortIdentifier'],
        'expires': (new Date(json['expires'])),
    };
}

  export function UrlToJSON(json: any): Url {
      return UrlToJSONTyped(json, false);
  }

  export function UrlToJSONTyped(value?: Url | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'imageUrl': value['imageUrl'],
        'name': value['name'],
        'description': value['description'],
        'url': value['url'],
        'identifier': value['identifier'],
        'shortIdentifier': value['shortIdentifier'],
        'expires': ((value['expires']).toISOString()),
    };
}

