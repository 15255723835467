import { Cookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

const cookies = new Cookies();
const TOKEN_KEY = "jwt_token";

interface TokenPayload {
  sub: string;
  name: string;
  exp: number;
}

export const setToken = (token: string): void => {
  const decoded = jwtDecode<TokenPayload>(token);
  const expiryDate = new Date(decoded.exp * 1000);

  cookies.set(TOKEN_KEY, token, {
    expires: expiryDate,
    path: "/",
  });
};

export const getToken = (): string | null => {
  const token = cookies.get(TOKEN_KEY);
  if (!token) return null;

  try {
    const decoded = jwtDecode<TokenPayload>(token);
    if (decoded.exp * 1000 > Date.now()) {
      return token;
    }
    removeToken();
    return null;
  } catch {
    removeToken();
    return null;
  }
};

export const removeToken = (): void => {
  cookies.remove(TOKEN_KEY, { path: "/" });
};

export const isAuthenticated = async (): Promise<boolean> => {
  const token = getToken();
  return !!token;
};
