/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    privacy: ImagePrivacyEnum;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    uploadName: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    uploadMimetype: string;
    /**
     * 
     * @type {number}
     * @memberof Image
     */
    uploadSize: number;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    shortIdentifier: string;
}


/**
 * @export
 */
export const ImagePrivacyEnum = {
    Private: 'private',
    Shared: 'shared',
    Public: 'public'
} as const;
export type ImagePrivacyEnum = typeof ImagePrivacyEnum[keyof typeof ImagePrivacyEnum];


/**
 * Check if a given object implements the Image interface.
 */
export function instanceOfImage(value: object): value is Image {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('privacy' in value) || value['privacy'] === undefined) return false;
    if (!('uploadName' in value) || value['uploadName'] === undefined) return false;
    if (!('uploadMimetype' in value) || value['uploadMimetype'] === undefined) return false;
    if (!('uploadSize' in value) || value['uploadSize'] === undefined) return false;
    if (!('identifier' in value) || value['identifier'] === undefined) return false;
    if (!('shortIdentifier' in value) || value['shortIdentifier'] === undefined) return false;
    return true;
}

export function ImageFromJSON(json: any): Image {
    return ImageFromJSONTyped(json, false);
}

export function ImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Image {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'imageUrl': json['imageUrl'] == null ? undefined : json['imageUrl'],
        'name': json['name'],
        'description': json['description'],
        'privacy': json['privacy'],
        'uploadName': json['uploadName'],
        'uploadMimetype': json['uploadMimetype'],
        'uploadSize': json['uploadSize'],
        'identifier': json['identifier'],
        'shortIdentifier': json['shortIdentifier'],
    };
}

  export function ImageToJSON(json: any): Image {
      return ImageToJSONTyped(json, false);
  }

  export function ImageToJSONTyped(value?: Image | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'imageUrl': value['imageUrl'],
        'name': value['name'],
        'description': value['description'],
        'privacy': value['privacy'],
        'uploadName': value['uploadName'],
        'uploadMimetype': value['uploadMimetype'],
        'uploadSize': value['uploadSize'],
        'identifier': value['identifier'],
        'shortIdentifier': value['shortIdentifier'],
    };
}

