/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LoginCodeDto,
  LoginCodeResponse,
  LoginEmailDto,
  LoginEmailResponse,
} from '../models/index';
import {
    LoginCodeDtoFromJSON,
    LoginCodeDtoToJSON,
    LoginCodeResponseFromJSON,
    LoginCodeResponseToJSON,
    LoginEmailDtoFromJSON,
    LoginEmailDtoToJSON,
    LoginEmailResponseFromJSON,
    LoginEmailResponseToJSON,
} from '../models/index';

export interface LoginWithCodeRequest {
    loginCodeDto: LoginCodeDto;
}

export interface LoginWithEmailRequest {
    loginEmailDto: LoginEmailDto;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Login with a code delivered via email or SMS.
     */
    async loginWithCodeRaw(requestParameters: LoginWithCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginCodeResponse>> {
        if (requestParameters['loginCodeDto'] == null) {
            throw new runtime.RequiredError(
                'loginCodeDto',
                'Required parameter "loginCodeDto" was null or undefined when calling loginWithCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login/code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginCodeDtoToJSON(requestParameters['loginCodeDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginCodeResponseFromJSON(jsonValue));
    }

    /**
     * Login with a code delivered via email or SMS.
     */
    async loginWithCode(requestParameters: LoginWithCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginCodeResponse> {
        const response = await this.loginWithCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Start login process with email address. A login code is delivered to the provided email address.
     */
    async loginWithEmailRaw(requestParameters: LoginWithEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginEmailResponse>> {
        if (requestParameters['loginEmailDto'] == null) {
            throw new runtime.RequiredError(
                'loginEmailDto',
                'Required parameter "loginEmailDto" was null or undefined when calling loginWithEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginEmailDtoToJSON(requestParameters['loginEmailDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginEmailResponseFromJSON(jsonValue));
    }

    /**
     * Start login process with email address. A login code is delivered to the provided email address.
     */
    async loginWithEmail(requestParameters: LoginWithEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginEmailResponse> {
        const response = await this.loginWithEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
