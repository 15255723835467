import React, { useState } from "react";

interface ChipProps {
  name: string;
  imageUrl?: string;
  onDelete?: () => void;
  isEditing?: boolean;
}

const CrossIcon = () => (
  <svg
    viewBox="0 0 24 24"
    className="h-3.5 w-3.5"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
  >
    <path d="M18 6L6 18M6 6l12 12" />
  </svg>
);

export function Chip({ name, imageUrl, onDelete, isEditing = false }: ChipProps) {
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = () => {
    setIsSelected(!isSelected);
  };

  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onDelete?.();
  };

  const getInitial = () => {
    return name.charAt(0).toUpperCase();
  };

  return (
    <div
      className={`inline-flex h-8 items-stretch rounded-full pr-3 text-sm ${
        isSelected ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-900"
      } ${isEditing ? "min-w-0" : "min-w-[32px]"} cursor-pointer`}
      onClick={handleClick}
    >
      {isSelected ? (
        <button
          onClick={handleDelete}
          className="flex h-full w-8 items-center justify-center rounded-full hover:bg-blue-600"
        >
          <CrossIcon />
        </button>
      ) : imageUrl ? (
        <img src={imageUrl} alt={name} className="h-full w-8 rounded-full object-cover" />
      ) : (
        <div className="flex h-full w-8 items-center justify-center rounded-full bg-blue-200 text-xs font-medium text-gray-600">
          {getInitial()}
        </div>
      )}
      <span className="ml-1.5 flex items-center truncate">{name}</span>
    </div>
  );
}

//created to try out the functionality
export default function ChipDemo() {
  const [inputValue, setInputValue] = useState("");
  const [chips, setChips] = useState<string[]>([]);

  const handleDelete = (index: number) => {
    setChips(chips.filter((_, i) => i !== index));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim()) {
      setChips([...chips, inputValue.trim()]);
      setInputValue("");
    }
  };

  return (
    <div className="flex w-full flex-col">
      <div className="mb-2 flex flex-wrap gap-1">
        {chips.map((chip, index) => (
          <Chip key={index} name={chip} onDelete={() => handleDelete(index)} />
        ))}
        {inputValue && <Chip name={inputValue} isEditing={true} />}
      </div>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Whom do you want to add?"
        className="w-64 rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none"
      />
    </div>
  );
}
