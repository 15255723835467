import React, { ReactNode, useState } from "react";
import { NumberButton } from "../Rect";

interface WrapContainerProps {
  title: string;
  items: ReactNode[];
}

export default function WrapContainer({ title, items }: WrapContainerProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxVisibleItems = 6;
  const maxItemsForThreeRows = 12;

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const visibleItems = isExpanded
    ? items.slice(0, maxItemsForThreeRows)
    : items.slice(0, maxVisibleItems);

  const overflowCount = Math.min(
    items.length - maxVisibleItems,
    maxItemsForThreeRows - maxVisibleItems,
  );

  return (
    <div className="flex h-fit flex-col gap-2">
      <h2 className="text-left text-xl font-extrabold leading-7">{title}</h2>
      <div
        className={`flex flex-wrap items-center gap-2 ${
          isExpanded ? "max-h-28" : "max-h-20"
        } overflow-hidden`}
      >
        {visibleItems.map((item, index) => (
          <div key={index} className="min-w-fit">
            {item}
          </div>
        ))}
        {overflowCount > 0 && (
          <NumberButton number={overflowCount} onClick={handleToggleExpand} isHidden={isExpanded} />
        )}
      </div>
    </div>
  );
}
