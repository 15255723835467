import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import { CookiesProvider } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { removeToken, setToken, getToken } from "../../utils/auth";
import LoadingScreen from "./LoadingScreen";

interface TokenPayload {
  sub: string;
  name: string;
  exp: number;
}

interface AuthContextType {
  isLoggedIn: boolean;
  userId: string | null;
  userName: string | null;
  login: (token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userId, setUserId] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | null>(null);

  const updateUserFromToken = useCallback((token: string) => {
    try {
      const decoded = jwtDecode<TokenPayload>(token);
      if (decoded.exp * 1000 > Date.now()) {
        setUserId(decoded.sub);
        setUserName(decoded.name);
        setIsLoggedIn(true);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error decoding token:", error);
      return false;
    }
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      const token = getToken();
      if (token) {
        updateUserFromToken(token);
      } else {
        setIsLoggedIn(false);
      }
      setIsLoading(false);
    };

    checkAuth();
  }, [updateUserFromToken]);

  const login = useCallback(
    (token: string) => {
      setToken(token);
      updateUserFromToken(token);
      setIsLoggedIn(true);
    },
    [updateUserFromToken],
  );

  const logout = useCallback(() => {
    removeToken();
    setIsLoggedIn(false);
    setUserId(null);
    setUserName(null);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <CookiesProvider>
      <AuthContext.Provider value={{ isLoggedIn, userId, userName, login, logout }}>
        {children}
      </AuthContext.Provider>
    </CookiesProvider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
