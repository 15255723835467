import { useState, useRef, useEffect } from "react";

export const useAutoResizeTextArea = () => {
  const [message, setMessage] = useState("");
  const [textareaHeight, setTextareaHeight] = useState(0);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const previousHeightRef = useRef(0);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const scrollableDiv = document.getElementById("scrollableDiv");
    const previousHeight = previousHeightRef.current;

    textarea.style.height = "auto";
    const newHeight = Math.min(textarea.scrollHeight, 180);
    textarea.style.height = `${newHeight}px`;

    if (scrollableDiv && previousHeight !== newHeight) {
      const heightDiff = newHeight - previousHeight;
      scrollableDiv.scrollTop = scrollableDiv.scrollTop + heightDiff;
    }

    previousHeightRef.current = newHeight;
    setTextareaHeight(newHeight);
  };

  useEffect(() => {
    adjustHeight();
  }, [message]);

  const handleMessageChange = (newMessage: string) => {
    setMessage(newMessage);
  };

  return {
    message,
    textareaRef,
    handleMessageChange,
    textareaHeight,
  };
};
