interface RectProps {
  text: string;
}
interface NumberButtonProps {
  number: number;
  onClick: () => void;
  isHidden?: boolean;
}

export default function Rect({ text }: RectProps) {
  return <div className="flex h-[29px] w-fit items-center bg-white px-3 text-sm">{text}</div>;
}

export function NumberButton({ number, onClick, isHidden = false }: NumberButtonProps) {
  return (
    <div
      onClick={onClick}
      className="flex h-8 w-fit cursor-pointer items-center bg-gray-200 px-3 text-sm hover:bg-gray-300"
    >{`${isHidden ? "-" : "+"}${number}`}</div>
  );
}
