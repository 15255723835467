import React from "react";
import { Message } from "../../../../apiClient/generated/models/Message";

interface MessageWrapperProps {
  children: React.ReactNode;
  message: Message;
  onDeleteMessage: (id: string) => void;
  isDropdownOpen: boolean;
  onToggleDropdown: () => void;
  isCurrentUser: boolean;
}

const MessageWrapper: React.FC<MessageWrapperProps> = ({ children, isCurrentUser, message }) => {
  return (
    <div
      className={`group relative mb-4 flex items-end ${
        isCurrentUser ? "justify-end" : "justify-start"
      }`}
    >
      {!isCurrentUser && (
        <div className="absolute bottom-0 left-4">
          <div className="relative mr-2 flex h-8 w-8 shrink-0 items-end">
            <div className="absolute h-8 w-8 animate-pulse rounded-full bg-gray-200" />
            <img
              src={message.sender.imageUrl || "/default-avatar.png"}
              alt={message.sender.name}
              className="h-8 w-8 rounded-full object-cover ring-2 ring-white ring-offset-0"
              onLoad={(e) => {
                e.currentTarget.previousElementSibling?.remove();
              }}
            />
          </div>
        </div>
      )}
      <div className={`${!isCurrentUser ? "ml-14" : ""}`}>{children}</div>
    </div>
  );
};

export default MessageWrapper;
