import React from "react";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Item } from "../../../../../../apiClient/generated/models/Item";
import PDFPreview from "./PDF/PDFPreview";
import DownloadButton from "./DownloadButton";
import FileInfo from "./FileInfo";
import FileModal from "./PDF/PDFModal";
import { getFileExtension } from "../../../../../../utils/fileUtils";

// Set up the worker for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

interface FileItemProps {
  item: Item;
  isCurrentUser: boolean;
  senderName: string;
}

const FileItem: React.FC<FileItemProps> = ({ item, isCurrentUser, senderName }) => {
  const [showModal, setShowModal] = React.useState(false);

  const fileNameWithoutExtension = React.useMemo(() => {
    return item.name?.split(".").slice(0, -1).join(".") || item.name;
  }, [item.name]);

  const fileUrl = item.data?.url ?? "";
  const fileType = getFileExtension(item.name ?? "");

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = item.name ?? fileUrl;
    link.click();
  };

  const renderContent = () => {
    switch (fileType) {
      case "pdf":
        return (
          <>
            <PDFPreview
              documentUrl={fileUrl}
              onClick={() => setShowModal(true)}
              isCurrentUser={isCurrentUser}
            />
            {showModal && (
              <FileModal
                fileUrl={fileUrl}
                onClose={() => setShowModal(false)}
                senderName={senderName}
              />
            )}
          </>
        );
      default:
        //TODO: Check the positioning of the download button
        return <DownloadButton onClick={handleDownload} />;
    }
  };

  return (
    <div
      className={`flex max-w-[300px] flex-col overflow-hidden rounded-[14px] ${
        isCurrentUser ? "ml-auto" : "mr-auto"
      }`}
    >
      {renderContent()}
      <FileInfo
        fileName={fileNameWithoutExtension ?? ""}
        fileDescription={item.description}
        isCurrentUser={isCurrentUser}
      />
    </div>
  );
};

export default FileItem;
