import React from "react";
import { FaTimes } from "react-icons/fa";
import { FileStatus } from "./types";

interface FilePreviewProps {
  file: FileStatus;
  onRemove: () => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({ file, onRemove }) => {
  return (
    <div className="relative">
      <div className="h-20 w-20 overflow-hidden rounded">
        {file.fileType === "image" ? (
          <img
            src={file.localPreview || file.uploadedUrl}
            alt="Preview"
            className="h-full w-full rounded object-cover"
          />
        ) : (
          <div className="flex h-full w-full items-center justify-center bg-gray-200">
            <span className="text-sm">{file.file.name}</span>
          </div>
        )}

        {file.status === "uploading" && (
          <div className="absolute inset-0 flex items-center justify-center rounded bg-black bg-opacity-50">
            <div className="h-6 w-6 animate-spin rounded-full border-2 border-white border-t-transparent" />
          </div>
        )}

        {file.status === "error" && (
          <div className="absolute inset-0 rounded bg-red-500 bg-opacity-50">
            <div className="flex h-full items-center justify-center">
              <span className="text-sm text-white">Error</span>
            </div>
          </div>
        )}
      </div>

      <button
        type="button"
        onClick={onRemove}
        className="absolute -right-2 -top-2 rounded-full bg-red-500 p-1 text-white hover:bg-red-600"
      >
        <FaTimes size={12} />
      </button>
    </div>
  );
};

export default FilePreview;
