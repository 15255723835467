import { Link, useNavigate, useLocation, Outlet } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../(Auth)/AuthProvider";

interface NavItem {
  path: string;
  label: string;
}

function Root() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const navItems: NavItem[] = [
    { path: "/content", label: "Content" },
    { path: "/chat/1f6de05b-4b7b-4cfb-8565-d838cb5fbd15", label: "Chat 1" },
    { path: "/chat/c3dd0eb9-ea4f-41df-9cf0-604721eca3c1", label: "Chat 2" },
    { path: "/test", label: "Test" },
  ];

  const handleLogout = (): void => {
    logout();
    navigate("/enter-email");
  };

  const isActivePath = (path: string): boolean => {
    return path === "/content"
      ? location.pathname === "/" || location.pathname === path
      : location.pathname === path;
  };

  const handleBackdropClick = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="relative flex min-h-screen bg-gray-100">
      {/* Backdrop overlay for small screens */}
      <div
        className={`fixed inset-0 z-20 bg-black/30 backdrop-blur-md transition-opacity duration-300 sm:hidden ${
          isSidebarOpen ? "opacity-100" : "pointer-events-none opacity-0"
        }`}
        onClick={handleBackdropClick}
      />

      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="fixed left-4 top-4 z-40 w-12 rounded-lg bg-white p-2 shadow-lg hover:bg-gray-100"
        aria-label="Toggle Sidebar"
      >
        {isSidebarOpen ? "<" : ">"}
      </button>

      {/* Sidebar */}
      <div
        className={`fixed left-0 top-0 z-20 h-full bg-white shadow-lg transition-transform duration-300 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
        style={{ width: "16rem" }}
      >
        <div className="p-4 pt-16">
          <h2 className="mb-6 text-2xl font-semibold text-gray-700">Navigation</h2>
          <nav className="space-y-2">
            {navItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`block w-full rounded-lg px-4 py-2 transition-colors duration-150 ${
                  isActivePath(item.path)
                    ? "bg-default-gray text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                {item.label}
              </Link>
            ))}
            <button
              onClick={handleLogout}
              className="w-full rounded-lg px-4 py-2 text-left text-gray-600 transition-colors duration-150 hover:bg-gray-100"
            >
              Logout
            </button>
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <main
        className={`min-h-screen w-full transition-[margin] duration-300 ${
          isSidebarOpen ? "ml-64 overflow-hidden" : "overflow-auto sm:ml-20"
        }`}
      >
        <Outlet />
      </main>
    </div>
  );
}

export default Root;
