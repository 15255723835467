/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUrlDto
 */
export interface CreateUrlDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUrlDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUrlDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUrlDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUrlDto
     */
    imageIdentifier?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUrlDto
     */
    expires?: Date;
}

/**
 * Check if a given object implements the CreateUrlDto interface.
 */
export function instanceOfCreateUrlDto(value: object): value is CreateUrlDto {
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function CreateUrlDtoFromJSON(json: any): CreateUrlDto {
    return CreateUrlDtoFromJSONTyped(json, false);
}

export function CreateUrlDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUrlDto {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'imageIdentifier': json['imageIdentifier'] == null ? undefined : json['imageIdentifier'],
        'expires': json['expires'] == null ? undefined : (new Date(json['expires'])),
    };
}

  export function CreateUrlDtoToJSON(json: any): CreateUrlDto {
      return CreateUrlDtoToJSONTyped(json, false);
  }

  export function CreateUrlDtoToJSONTyped(value?: CreateUrlDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'url': value['url'],
        'name': value['name'],
        'description': value['description'],
        'imageIdentifier': value['imageIdentifier'],
        'expires': value['expires'] == null ? undefined : ((value['expires']).toISOString()),
    };
}

