import { useState, useEffect } from "react";

export const useVisibleDate = (scrollableId: string) => {
  const [visibleDate, setVisibleDate] = useState<Date | null>(null);

  useEffect(() => {
    const scrollableElement = document.getElementById(scrollableId);
    if (!scrollableElement) return;

    const calculateVisibleDate = () => {
      const dateSeparators = scrollableElement.querySelectorAll("[data-date]");
      const headerHeight = 60; // Updated to match top-[60px] in StickyDateHeader

      let currentDate: string | null = null;

      dateSeparators.forEach((separator) => {
        const rect = separator.getBoundingClientRect();
        const elementTop = rect.top;

        if (elementTop <= headerHeight + 4) {
          // Added small buffer for better detection
          currentDate = separator.getAttribute("data-date");
        }
      });

      if (currentDate) {
        setVisibleDate(new Date(currentDate));
      }
    };

    const handleScroll = () => {
      requestAnimationFrame(calculateVisibleDate);
    };

    scrollableElement.addEventListener("scroll", handleScroll);
    calculateVisibleDate(); // Initial calculation

    return () => {
      scrollableElement.removeEventListener("scroll", handleScroll);
    };
  }, [scrollableId]);

  return visibleDate;
};
