import React from "react";
import FileDetails from "./FileDetails";

export interface CardMediumProps {
  imageUrl?: string;
  name: string;
  file?: File;
}

export default function CardMedium({ imageUrl, name, file }: CardMediumProps) {
  return (
    <a className="flex h-[300px] w-[205px] flex-col overflow-hidden rounded-bl-xl rounded-br-none rounded-tl-xl rounded-tr-xl no-underline">
      <div className="h-[145px]">
        <img src={imageUrl} alt={`${name} card`} className="h-full w-full object-cover" />
      </div>
      <div className="flex h-[155px] flex-col justify-between bg-white p-3">
        <h3 className="line-clamp-4 py-1 text-[17px] font-bold leading-[1.38]">{name}</h3>
        <div className="flex flex-col">{file && <FileDetails file={file} />}</div>
      </div>
    </a>
  );
}
