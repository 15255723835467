import React from "react";
import { FileStatus } from "../types";

interface MessageInputProps {
  message: string;
  files: FileStatus[];
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  onChange: (value: string) => void;
  children?: React.ReactNode;
}

export const MessageInput: React.FC<MessageInputProps> = ({
  message,
  textareaRef,
  onChange,
  children,
}) => {
  return (
    <div className="flex flex-1 flex-col rounded-[24px] bg-white py-2 pl-4 pr-2">
      {children}
      <textarea
        ref={textareaRef}
        value={message}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Type a message..."
        className="max-h-[120px] min-h-[24px] resize-none bg-transparent text-sm text-[#1A1A1A] placeholder-[#6D6D72] focus:outline-none"
        rows={1}
      />
    </div>
  );
};
