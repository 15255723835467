import React from "react";
import { LuPlus } from "react-icons/lu";

interface AttachmentButtonProps {
  onClick: () => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  onFileSelect: (files: FileList | null) => void;
}

export const AttachmentButton: React.FC<AttachmentButtonProps> = ({
  onClick,
  fileInputRef,
  onFileSelect,
}) => {
  return (
    <>
      <button
        type="button"
        onClick={onClick}
        className="flex h-[40px] w-[40px] shrink-0 items-center justify-center rounded-full bg-white text-[#4C5459] shadow-sm hover:bg-gray-50"
      >
        <LuPlus className="h-5 w-5 stroke-[1.5]" />
      </button>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*,.pdf,.doc,.docx,.txt,.zip,.mp3,.mp4,.wav,.webm"
        multiple
        onChange={(e) => onFileSelect(e.target.files)}
        className="hidden"
      />
    </>
  );
};
