import Accordion from "./components/Accordion";
import CardLarge from "./components/CardLarge";
import CardMedium from "./components/CardMedium";
import CardSmall from "./components/CardSmall";
import CardWide from "./components/CardWide";
import HorizontalContainer from "./components/containers/HorizontalContainer";
import TabContainer from "./components/containers/TabContainer";
import VerticalContainer from "./components/containers/VerticalContainer";
import WrapContainer from "./components/containers/WrapContainer";
import ImageWide from "./components/ImageWide";
import Rect from "./components/Rect";
import Row from "./components/Row";
import TextRich from "./components/TextRich";
import EmbedYoutube from "./components/EmbedYoutube";
import EmbedSpotify from "./components/EmbedSpotify";
import ChipDemo from "./components/Chip";
import TableB from "../../components/ui/tableB";
import { Item, Page, PagesApi, TagsApi } from "../../apiClient/generated";
import { getApiConfig } from "../../apiClient/config";
import { useEffect, useState } from "react";

const pagesApi = new PagesApi(getApiConfig());
const tagsApi = new TagsApi(getApiConfig());

const Content = () => {
  const [pageLargeData, setPageLargeData] = useState<Page | null>(null);
  const [tagsData, setTagsData] = useState<any | null>(null);

  useEffect(() => {
    pagesApi.getPage({ id: "48b2233c-2409-47b6-942d-e8225660ecea" }).then((page) => {
      setPageLargeData(page);
      console.log(page);
    });

    tagsApi.getTags().then((tags) => {
      setTagsData(tags);
      console.log("tags", tags);
    });
  }, []);

  const cardLargeData = pageLargeData?.contentArea?.contentContainers[1]?.items[0];
  const getCardMediumItems = () => {
    return pageLargeData?.contentArea?.contentContainers[0]?.items || [];
  };

  const renderCardMedium = (item: Item) => (
    <CardMedium imageUrl={item.imageUrl} name={item.name} file={mockLargeFile} />
  );

  const textRichData = pageLargeData?.contentArea?.contentContainers[2];

  const accordionData = [
    {
      heading: "Accordion title 1",
      content: "Content for the first accordion item goes here",
    },
    {
      heading: "Accordion title 2",
      content:
        "Content for the second accordion item goes here Content for the second accordion item goes here Content for the second accordion item goes here",
    },
    {
      heading: "Accordion title 3",
      content:
        "Content for the accordion third item Content for the accordion third item Lorem ipsum Eeron vielä ollessa äitinsä helmoissa kotona, kuusi vanhinta veljestä varasti naapurista kananmunia ja karkasi sitten metsään. Karkulaisten elämä metsässä kesti muutaman päivän ennen kuin äiti apujoukkoineen löysi heidät.  Seurasi kova löylytys.",
    },
  ];

  const mockLargeFile = new File(["Large content".repeat(1024 * 1024)], "large-document.txt", {
    type: "text/plain",
  });

  const tabs = [
    {
      id: "home",
      name: "Home",
      navItems: [
        {
          components: [
            <WrapContainer
              title="Topics I know about"
              items={[
                <Rect text="Marketing"></Rect>,
                <Rect text="Research"></Rect>,
                <Rect text="Development"></Rect>,
                <Rect text="Marketing"></Rect>,
                <Rect text="AI"></Rect>,
                <Rect text="Web3"></Rect>,
                <Rect text="Blockchain"></Rect>,
                <Rect text="Web Development"></Rect>,
                <Rect text="IOS Development"></Rect>,
              ]}
            />,
            <TableB tags={tagsData} />,
            <VerticalContainer
              title="H2 Accordion Container"
              items={[<Accordion items={accordionData} />]}
            />,
            <HorizontalContainer
              title="H2 Some People"
              items={[
                <CardSmall
                  image={"/coffeeImagePlaceholder.png"}
                  text={"John Smith"}
                  link={"/content"}
                />,
                <CardSmall
                  image={"/coffeeImagePlaceholder.png"}
                  text={"John Doe"}
                  link={"/content"}
                />,
                <CardSmall
                  image={"/coffeeImagePlaceholder.png"}
                  text={"John Black"}
                  link={"/content"}
                />,
                <CardSmall
                  image={"/coffeeImagePlaceholder.png"}
                  text={"John Doe"}
                  link={"/content"}
                />,
                <CardSmall
                  image={"/coffeeImagePlaceholder.png"}
                  text={"John Black"}
                  link={"/content"}
                />,
              ]}
            />,
            <HorizontalContainer
              title="H2 Some People"
              items={[...getCardMediumItems().map(renderCardMedium)]}
            />,
          ],
        },
      ],
    },
    {
      id: "explore",
      name: "Explore",
      navItems: [
        {
          components: [
            <CardLarge
              imageUrl={cardLargeData?.imageUrl}
              name={cardLargeData?.name}
              description={cardLargeData?.description}
              file={mockLargeFile}
            />,
          ],
        },
      ],
    },
    {
      id: "marketing",
      name: "Marketing",
      navItems: [
        {
          components: [
            <EmbedYoutube videoId="wDHWNQ88910" />,
            <EmbedSpotify src="https://open.spotify.com/embed/track/21AUuDbg1mKMbaWLfcwUo8?utm_source=generator&theme=0" />,
          ],
        },
      ],
    },
    {
      id: "people",
      name: "People",
      navItems: [
        {
          components: [
            <WrapContainer title="Chip Demo" items={[<ChipDemo />]} />,
            <VerticalContainer
              title="H2 Some People"
              items={[
                <Row
                  image={"/profile-pic.png"}
                  heading="h3 Annu"
                  text="I’m the marketing manager and co-founder of Sisters in Business and also some other text here just so we know it cuts off"
                  link={"/content"}
                  isOnline={true}
                  isMentor={true}
                  badgeNumber={14}
                ></Row>,
                <Row
                  image={"/profile-pic.png"}
                  heading="h3 Annu"
                  text="I’m the marketing manager and co-founder of Sisters in Business and also some other text here just so we know it cuts off"
                  link={"/content"}
                  isOnline={true}
                  isMentor={false}
                  badgeNumber={5}
                ></Row>,
                <Row
                  image={"/profile-pic.png"}
                  heading="h3 Annu"
                  text="I’m the marketing manager and co-founder of Sisters in Business and also some other text here just so we know it cuts off"
                  link={"/content"}
                  isOnline={false}
                  isMentor={true}
                ></Row>,
                <TextRich description={textRichData?.description} />,
              ]}
            />,
          ],
        },
      ],
    },
    {
      id: "news",
      name: "News",
      navItems: [
        {
          components: [
            <VerticalContainer
              title="H2 Some Title"
              items={[
                <CardWide
                  image={"/imagePlaceholder.png"}
                  heading="h3 The 10 key success factors in implementing design thinking in big organizations"
                  link={"/content"}
                />,
                <CardWide
                  image={"/imagePlaceholder.png"}
                  heading="h3 The 10 key success factors in implementing design thinking in big organizations"
                  link={"/content"}
                />,
                <CardWide
                  image={"/imagePlaceholder.png"}
                  heading="h3 The 10 key success factors in implementing design thinking in big organizations"
                  link={"/content"}
                />,
              ]}
            />,
            <ImageWide
              src={"/imageWide.png"}
              alt="A bokeh picture taken at the event"
              caption="A bokeh picture taken at the event"
            />,
          ],
        },
      ],
    },
  ];

  return (
    <div className="flex h-screen flex-col gap-4 border-l-2 px-3 text-default-gray">
      <TabContainer tabs={tabs} />
    </div>
  );
};

export default Content;
