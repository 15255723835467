import React, { useState, useRef } from "react";
import { Item } from "../../../../../../apiClient/generated/models/Item";
import ImageModal from "./ImageModal";

interface ImageItemProps {
  item: Item;
  isCurrentUser: boolean;
  senderName: string;
  senderIcon?: string;
}

const ImageItem: React.FC<ImageItemProps> = ({ item, isCurrentUser, senderName, senderIcon }) => {
  const [showModal, setShowModal] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleImageClick = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div ref={containerRef} className="flex flex-col gap-2">
      <ImageObject
        imageUrl={item.imageUrl ?? ""}
        senderName={senderName}
        onClick={handleImageClick}
        isCurrentUser={isCurrentUser}
      />

      {showModal && (
        <ImageModal
          imageUrl={item.imageUrl ?? ""}
          alt={`From ${senderName}`}
          onClose={handleCloseModal}
          senderName={senderName}
          senderIcon={senderIcon ?? ""}
        />
      )}
    </div>
  );
};

interface ImageObjectProps {
  imageUrl: string;
  senderName: string;
  onClick: () => void;
  isCurrentUser: boolean;
}

const ImageObject: React.FC<ImageObjectProps> = ({
  imageUrl,
  senderName,
  onClick,
  isCurrentUser,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleImageLoad = () => setImageLoaded(true);
  const handleImageError = () => setImageError(true);

  const getRounding = () => {
    return isCurrentUser ? "rounded-[13px_13px_0px_13px]" : "rounded-[13px_13px_13px_0px]";
  };

  return (
    <div className="relative max-w-2xl">
      {!imageLoaded && !imageError && (
        <div
          className={`animate-pulse bg-gray-200 ${getRounding()}`}
          style={{
            width: "300px",
            height: "300px",
          }}
        />
      )}
      {!imageError && imageUrl && (
        <div className={`overflow-hidden ${getRounding()}`}>
          <img
            src={`${imageUrl}?size=card`}
            alt={`From ${senderName}`}
            className={`h-[300px] w-full max-w-full cursor-pointer bg-white transition-opacity duration-300 ${
              imageLoaded ? "opacity-100" : "absolute left-0 top-0 opacity-0"
            }`}
            style={{
              objectFit: "cover",
            }}
            onLoad={handleImageLoad}
            onError={handleImageError}
            onClick={onClick}
            loading="lazy"
          />
        </div>
      )}
      {imageError && (
        <div
          className={`flex h-48 w-full items-center justify-center bg-gray-200 ${getRounding()}`}
        >
          <p className="text-sm text-gray-500">Failed to load image</p>
        </div>
      )}
    </div>
  );
};

export default ImageItem;
