import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface TextRichProps {
  description?: string;
}

interface CodeProps extends React.HTMLProps<HTMLElement> {
  inline?: boolean;
  children?: React.ReactNode;
}

const formatJSONString = (str: string) => {
  try {
    const parsed = JSON.parse(str);
    return JSON.stringify(parsed, null, 2);
  } catch {
    return str;
  }
};

export function CodeComponent({ inline, children, ...props }: CodeProps) {
  const content = React.useMemo(() => {
    const rawContent = children?.toString() || "";
    if (!inline && rawContent.trim().startsWith("{")) {
      return formatJSONString(rawContent);
    }
    return rawContent;
  }, [children, inline]);

  const shouldBeInline =
    inline ||
    (!content.includes("\n") && !content.trim().startsWith("{") && !content.trim().endsWith("}"));

  // Inline code (single backticks)
  if (shouldBeInline) {
    return (
      <code
        className="rounded-lg border-2 border-default-gray border-opacity-10 bg-gray-50 px-1.5 py-0.5 font-mono text-[15px] text-red-800"
        {...props}
      >
        {content}
      </code>
    );
  }

  // Code blocks (triple backticks)
  return (
    <div className="my-4 w-full">
      <pre
        className="w-full overflow-x-auto rounded-lg border-2 border-default-gray border-opacity-10 bg-gray-50 p-2 font-mono text-[15px] text-default-gray"
        {...(props as React.HTMLProps<HTMLPreElement>)}
      >
        <code className="block w-full whitespace-pre">{content}</code>
      </pre>
    </div>
  );
}

export default function TextRich({ description }: TextRichProps) {
  return (
    <ReactMarkdown
      children={description}
      remarkPlugins={[remarkGfm]}
      components={{
        h2: (props: React.HTMLProps<HTMLHeadingElement>) => (
          <h2
            className="mb-2 text-left text-[20px] text-xl font-extrabold leading-[26px]"
            {...props}
          />
        ),
        p: (props: React.HTMLProps<HTMLParagraphElement>) => (
          <p className="mb-4 text-[17px]" {...props} />
        ),
        a: (props: React.HTMLProps<HTMLAnchorElement>) => (
          <a
            className="text-blue-500 underline"
            target="_blank"
            rel="noopener noreferrer"
            {...props}
          />
        ),
        strong: (props: React.HTMLProps<HTMLElement>) => (
          <strong className="font-bold" {...props} />
        ),
        em: (props: React.HTMLProps<HTMLElement>) => <em className="italic" {...props} />,
        code: (props: CodeProps) => <CodeComponent {...props} />,
        ul: (props: React.HTMLProps<HTMLUListElement>) => (
          <ul className="ml-6 list-disc" {...props} />
        ),
        ol: (props: React.HTMLProps<HTMLOListElement>) => {
          const { type, ...restProps } = props;
          const validTypes: ("1" | "A" | "I" | "a" | "i" | undefined)[] = ["1", "A", "I", "a", "i"];
          const validType = validTypes.includes(type as "1" | "A" | "I" | "a" | "i")
            ? (type as "1" | "A" | "I" | "a" | "i")
            : "1";

          return <ol className="ml-6 list-decimal" type={validType} {...restProps} />;
        },
        li: (props: React.HTMLProps<HTMLLIElement>) => <li className="mb-1" {...props} />,
      }}
    />
  );
}
