import React from "react";
import { FaRegPaperPlane, FaChevronDown } from "react-icons/fa";
import { InputAreaProps } from "./types";
import { useInputArea } from "./hooks/useInputArea";
import { AttachmentButton } from "./components/AttachmentButton";
import { MessageInput } from "./components/MessageInput";
import { FilePreviewList } from "./components/FilePreviewList";
import { AnimatePresence, motion } from "framer-motion";

export const InputArea: React.FC<InputAreaProps> = ({
  onSendMessage,
  onHeightChange,
  showScrollButton,
  onScrollToBottom,
}) => {
  const {
    message,
    files,
    textareaRef,
    fileInputRef,
    handleMessageChange,
    handleFileSelect,
    handleRemoveFile,
    handleSubmit,
  } = useInputArea({ onSendMessage, onHeightChange });

  return (
    <form onSubmit={handleSubmit} className="relative bottom-0 left-0 right-0 flex bg-transparent">
      <div className="mx-auto w-full max-w-3xl">
        <div className="relative flex items-end gap-2 px-4 py-2">
          <AttachmentButton
            onClick={() => fileInputRef.current?.click()}
            fileInputRef={fileInputRef}
            onFileSelect={handleFileSelect}
          />

          <MessageInput
            message={message}
            files={files}
            textareaRef={textareaRef}
            onChange={handleMessageChange}
          >
            {files.length > 0 && <FilePreviewList files={files} onRemoveFile={handleRemoveFile} />}
          </MessageInput>

          <div className="relative">
            <AnimatePresence>
              {showScrollButton && (
                <motion.button
                  type="button"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  onClick={onScrollToBottom}
                  className="absolute bottom-[52px] right-0 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[#64CD9B] text-white shadow-sm transition-transform hover:scale-105 active:scale-95"
                >
                  <FaChevronDown className="h-4 w-4" />
                </motion.button>
              )}
            </AnimatePresence>

            <button
              type="submit"
              disabled={!message.trim() && files.length === 0}
              className="flex h-[40px] w-[40px] shrink-0 items-center justify-center rounded-full bg-[#64CD9B] text-white shadow-sm transition-colors hover:bg-[#58b589] disabled:bg-gray-300"
            >
              <FaRegPaperPlane className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
