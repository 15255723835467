/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Item } from './Item';
import {
    ItemFromJSON,
    ItemFromJSONTyped,
    ItemToJSON,
    ItemToJSONTyped,
} from './Item';

/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    status: MessageStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    body: string;
    /**
     * 
     * @type {Date}
     * @memberof Message
     */
    time: Date;
    /**
     * 
     * @type {Array<Item>}
     * @memberof Message
     */
    items: Array<Item>;
    /**
     * 
     * @type {Item}
     * @memberof Message
     */
    sender: Item;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    type: MessageTypeEnum;
}


/**
 * @export
 */
export const MessageStatusEnum = {
    Draft: 'draft',
    Sent: 'sent',
    Delivered: 'delivered',
    Read: 'read',
    Deleted: 'deleted'
} as const;
export type MessageStatusEnum = typeof MessageStatusEnum[keyof typeof MessageStatusEnum];

/**
 * @export
 */
export const MessageTypeEnum = {
    Message: 'message',
    Info: 'info'
} as const;
export type MessageTypeEnum = typeof MessageTypeEnum[keyof typeof MessageTypeEnum];


/**
 * Check if a given object implements the Message interface.
 */
export function instanceOfMessage(value: object): value is Message {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('body' in value) || value['body'] === undefined) return false;
    if (!('time' in value) || value['time'] === undefined) return false;
    if (!('items' in value) || value['items'] === undefined) return false;
    if (!('sender' in value) || value['sender'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function MessageFromJSON(json: any): Message {
    return MessageFromJSONTyped(json, false);
}

export function MessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Message {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': json['status'],
        'body': json['body'],
        'time': (new Date(json['time'])),
        'items': ((json['items'] as Array<any>).map(ItemFromJSON)),
        'sender': ItemFromJSON(json['sender']),
        'type': json['type'],
    };
}

  export function MessageToJSON(json: any): Message {
      return MessageToJSONTyped(json, false);
  }

  export function MessageToJSONTyped(value?: Message | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'status': value['status'],
        'body': value['body'],
        'time': ((value['time']).toISOString()),
        'items': ((value['items'] as Array<any>).map(ItemToJSON)),
        'sender': ItemToJSON(value['sender']),
        'type': value['type'],
    };
}

