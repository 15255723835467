export interface AppConfig {
  api: {
    basePath: string;
    mediaPath: string;
  };
  socket: {
    url: string;
  };
}

const developmentConfig: AppConfig = {
  api: {
    basePath: "https://devapi.mesensei.com",
    mediaPath: "https://devmedia.mesensei.com",
  },
  socket: {
    url: "https://devapi.mesensei.com",
  },
};

// const productionConfig: AppConfig = {
//   api: {
//     basePath: "https://api.mesensei.com",
//     mediaPath: "https://media.mesensei.com",
//   },
//   socket: {
//     url: "https://api.mesensei.com",
//   },
// };

const getConfig = (): AppConfig => {
  return developmentConfig;
};

export const config = getConfig();
