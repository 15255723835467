import React from "react";
import { formatDate } from "../../../../../utils/dateFormatting";

interface DateSeparatorProps {
  date: Date;
}

const DateSeparator: React.FC<DateSeparatorProps> = ({ date }) => {
  return (
    <div className="my-4 flex items-center justify-center px-4">
      <div className="rounded-full bg-white px-4 py-1 text-sm text-gray-500">
        {formatDate(date)}
      </div>
    </div>
  );
};

export default DateSeparator;
