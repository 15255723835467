import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useChat } from "./hooks/useChat";
import InfiniteScroll from "react-infinite-scroll-component";
import MessageList from "./components/Message/MessageList";
import { InputArea } from "./components/InputArea/InputArea";
import TopBar from "./components/TopBar";
import StickyDateHeader from "./components/Message/Date/StickyDateHeader";
import { CreateMessageDto } from "../../apiClient/generated/models/CreateMessageDto";
import { useScrollDetection } from "./hooks/useScrollDetection";
import { useVisibleDate } from "./hooks/useVisibleDate";
import { useScrollToBottom } from "./hooks/useScrollToBottom";
import LoadingMessageList from "./components/Message/LoadingMessageList";

const Chat: React.FC = () => {
  const { chatId } = useParams<{ chatId: string }>();
  const { messages, chatInfo, addMessage, deleteMessage, error, loadMoreMessages, hasMore } =
    useChat(chatId ?? "");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const visibleDate = useVisibleDate("scrollableDiv");
  const isScrolling = useScrollDetection("scrollableDiv");
  const { showScrollButton, scrollToBottom } = useScrollToBottom("scrollableDiv");
  const [inputAreaHeight, setInputAreaHeight] = useState(64); // Default height for input area

  useEffect(() => {
    if (!showScrollButton) {
      scrollToBottom();
    }
  }, [messages, showScrollButton]);

  const handleInputAreaHeightChange = (height: number) => {
    setInputAreaHeight(height + 20); // Add padding
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!messages) {
    return (
      <div className="flex h-screen flex-col bg-[#F1F2F4]">
        <TopBar chatName="Loading..." />
        <div className="mt-[50px] flex-grow overflow-y-auto">
          <div className="mx-auto w-full max-w-3xl">
            <LoadingMessageList />
          </div>
        </div>
      </div>
    );
  }

  const handleSendMessage = (messageDto: CreateMessageDto) => {
    addMessage(messageDto);
    setInputAreaHeight(64);
  };

  return (
    <div className="relative flex h-screen min-w-[300px] shrink-0 flex-col bg-[#F1F2F4]">
      <TopBar chatName={chatInfo?.name ?? "Chat"} />
      <div
        className="relative flex-grow overflow-y-auto"
        id="scrollableDiv"
        style={{ paddingBottom: `${inputAreaHeight}px` }}
      >
        <div className="sticky top-0 z-10">
          <div className="mx-auto w-full max-w-3xl px-4">
            <StickyDateHeader date={visibleDate} isVisible={isScrolling} />
          </div>
        </div>
        <InfiniteScroll
          dataLength={messages.length}
          next={loadMoreMessages}
          hasMore={hasMore}
          inverse={true}
          loader={
            <div className="flex justify-center py-4">
              <div className="h-8 w-8 animate-spin rounded-full border-2 border-gray-300 border-t-[#64CD9B]" />
            </div>
          }
          style={{ display: "flex", flexDirection: "column-reverse" }}
          scrollableTarget="scrollableDiv"
        >
          <div className="mx-auto w-full max-w-3xl p-4">
            <MessageList messages={messages} onDeleteMessage={deleteMessage} />
            <div ref={messagesEndRef} />
          </div>
        </InfiniteScroll>
      </div>
      <InputArea
        onSendMessage={handleSendMessage}
        onHeightChange={handleInputAreaHeightChange}
        showScrollButton={showScrollButton}
        onScrollToBottom={scrollToBottom}
      />
    </div>
  );
};

export default Chat;
