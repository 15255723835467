import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./table";

export default function TableB({ tags }: { tags: any }) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px] p-4 pt-2">Topic</TableHead>
          <TableHead>Keywords</TableHead>
          <TableHead>Posts</TableHead>
          <TableHead className="text-right">Disscussion Enabled</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {tags?.map((tag: any) => (
          <TableRow>
            <TableCell className="font-medium">INV001</TableCell>
            <TableCell>{tag.name}</TableCell>
            <TableCell>0</TableCell>
            <TableCell className="text-right text-test-red">0</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
