import React from "react";
import { Message } from "../../../../../apiClient/generated/models/Message";
import ImageMessage from "./ImageItem/ImageItem";
import FileMessage from "./FileItem/FileItem";
import ItemWrapper from "../ItemWrapper";
import { Item } from "../../../../../apiClient/generated/models/Item";
import { ItemItemTypeEnum } from "../../../../../apiClient/generated/models/Item";
import UrlMessage from "./UrlItem/UrlItem";

interface MessageContentProps {
  message: Message;
  isCurrentUser: boolean;
}

const ITEM_TYPE_ORDER: Record<string, number> = {
  [ItemItemTypeEnum.Url]: 1,
  [ItemItemTypeEnum.Document]: 2,
  [ItemItemTypeEnum.Video]: 3,
  [ItemItemTypeEnum.Image]: 4,
};

const MessageContent: React.FC<MessageContentProps> = ({ message, isCurrentUser }) => {
  if (!message.items?.length) {
    return (
      <ItemWrapper message={message} isCurrentUser={isCurrentUser} showBody={true}>
        <></>
      </ItemWrapper>
    );
  }

  const sortedItems = React.useMemo(() => {
    return [...message.items].sort((a, b) => {
      const aOrder = ITEM_TYPE_ORDER[a.itemType] ?? 999;
      const bOrder = ITEM_TYPE_ORDER[b.itemType] ?? 999;
      return aOrder - bOrder;
    });
  }, [message.items]);

  const renderItem = (item: Item) => {
    switch (item.itemType) {
      case ItemItemTypeEnum.Image:
        return (
          <ImageMessage
            item={item}
            isCurrentUser={isCurrentUser}
            senderName={message.sender.name}
            senderIcon={message.sender.imageUrl}
          />
        );
      case ItemItemTypeEnum.Url:
        return (
          <UrlMessage
            item={item}
            isCurrentUser={isCurrentUser}
            senderName={message.sender.name}
            senderIcon={message.sender.imageUrl}
          />
        );
      case ItemItemTypeEnum.Document:
      case ItemItemTypeEnum.Video:
        return (
          <FileMessage item={item} isCurrentUser={isCurrentUser} senderName={message.sender.name} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex max-w-[300px] flex-col gap-2">
      {sortedItems.map((item, index) => {
        const isLast = index === message.items.length - 1;
        const itemMessage: Message = {
          ...message,
          items: [item],
          body: isLast ? message.body : "",
        };

        return (
          <ItemWrapper
            key={item.id}
            message={itemMessage}
            isCurrentUser={isCurrentUser}
            showSender={index === 0}
            showTimestamp={isLast}
            showBody={isLast}
          >
            {renderItem(item)}
          </ItemWrapper>
        );
      })}
    </div>
  );
};

export default MessageContent;
