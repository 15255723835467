import React from "react";
import { FaFileAlt } from "react-icons/fa";

interface FileInfoProps {
  fileName: string;
  fileDescription: string;
  isCurrentUser: boolean;
}

const FileInfo: React.FC<FileInfoProps> = ({ fileName, fileDescription, isCurrentUser }) => {
  return (
    <div className="m-2 flex flex-col">
      <h3 className={`mt-2 text-lg font-bold ${isCurrentUser ? "text-right" : "text-left"}`}>
        {fileName}
      </h3>
      <div
        className={`mt-1 flex items-center text-xs text-gray-600 ${isCurrentUser ? "justify-start" : "justify-start"}`}
      >
        <FaFileAlt className={isCurrentUser ? "order-2 ml-2" : "mr-2"} />
        <span>{fileDescription}</span>
      </div>
    </div>
  );
};

export default FileInfo;
