import { FilesApi, GetImageSizeEnum } from "../../../apiClient/generated/apis/FilesApi";
import { getApiConfig } from "../../../apiClient/config";
import { Image } from "../../../apiClient/generated/models/Image";
import { Configuration } from "../../../apiClient/generated";

export const getImageUrl = async (
  identifier: string,
  size: GetImageSizeEnum,
): Promise<string | undefined> => {
  try {
    const baseConfig = getApiConfig();
    const config = new Configuration({
      basePath: baseConfig.basePath,
      headers: {
        ...baseConfig.headers,
      },
    });

    const filesApi = new FilesApi(config);
    const response = await filesApi.getImage({
      identifier,
      size,
    });

    return response[0].imageUrl;
  } catch (error) {
    console.error("Error fetching image:", error);
    throw error;
  }
};

const isImageFile = (file: File): boolean => {
  return file.type.startsWith("image/");
};

export const uploadFile = async (file: File): Promise<Image> => {
  try {
    const baseConfig = getApiConfig();
    const config = new Configuration({
      basePath: baseConfig.basePath,
      headers: {
        ...baseConfig.headers,
      },
    });

    const filesApi = new FilesApi(config);
    let rawResponse;

    if (isImageFile(file)) {
      rawResponse = await filesApi.uploadImageRaw({
        image: file,
      });
    } else {
      rawResponse = await filesApi.uploadDocumentRaw({
        file: file,
      });
    }

    if (!rawResponse.raw.ok) {
      throw new Error(`Upload failed with status: ${rawResponse.raw.status}`);
    }

    const jsonResponse = await rawResponse.raw.json();

    // Ensure we have all required fields for the Image interface
    const fileData: Image = {
      id: jsonResponse.id,
      imageUrl: jsonResponse.imageUrl || "",
      name: jsonResponse.name || file.name,
      description: jsonResponse.description || "",
      privacy: jsonResponse.privacy || "shared",
      uploadMimetype: jsonResponse.uploadMimetype || file.type,
      uploadSize: jsonResponse.uploadSize || file.size,
      identifier: jsonResponse.identifier,
      shortIdentifier: jsonResponse.shortIdentifier,
      uploadName: jsonResponse.uploadName || file.name,
    };

    // Validate the file data
    if (!fileData.id || !fileData.identifier) {
      throw new Error("Invalid file data received from server");
    }

    return fileData;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const uploadMultipleFiles = async (
  files: File[],
): Promise<Array<{ file: File; result: Image }>> => {
  const results: Array<{ file: File; result: Image }> = [];
  const errors: Error[] = [];

  // Upload files sequentially and maintain original file reference
  for (const file of files) {
    try {
      const result = await uploadFile(file);
      results.push({ file, result });
    } catch (error) {
      console.error("Error uploading file:", error);
      errors.push(error as Error);
    }
  }

  if (errors.length > 0) {
    console.error(`${errors.length} files failed to upload`);
    throw new Error("Some files failed to upload");
  }

  return results;
};
