import React from "react";
import FileDetails from "./FileDetails";
import LinkDetails from "./LinkDetails";

interface CardLargeProps {
  imageUrl?: string;
  name?: string;
  description?: string;
  file?: File;
}

const linkDetails = "https://www.eduskunta.fi/FI/sivut/default.aspx";

export default function CardLarge({ imageUrl, name, description, file }: CardLargeProps) {
  return (
    <a className="flex h-[340px] w-full min-w-[349px] flex-col overflow-hidden rounded-bl-xl rounded-br-none rounded-tl-xl rounded-tr-xl no-underline sm:w-[349px]">
      <div className="h-[185px]">
        <img src={imageUrl} alt="Card" className="h-full w-full object-cover" />
      </div>
      <div className="flex h-[155px] flex-col gap-2 bg-white p-3 leading-5">
        <h3 className="line-clamp-2 text-[17px] font-bold">{name}</h3>
        <p className="line-clamp-2 text-[17px]">{description}</p>
        {file && <FileDetails file={file} />}
        {<LinkDetails url={linkDetails} />}
      </div>
    </a>
  );
}
