import React, { useState } from "react";
import { Item } from "../../../../../../apiClient/generated/models/Item";

interface UrlItemProps {
  item: Item;
  isCurrentUser: boolean;
  senderName: string;
  senderIcon?: string;
}

const UrlItem: React.FC<UrlItemProps> = ({ item, isCurrentUser }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleImageLoad = () => setImageLoaded(true);
  const handleImageError = () => setImageError(true);

  const getRounding = () => {
    return isCurrentUser ? "rounded-[13px_13px_0px_13px]" : "rounded-[0px_13px_13px_0px]";
  };

  const handleUrlClick = () => {
    if (item.data?.url) {
      window.open(item.data.url, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <div className="flex w-full flex-col gap-2">
      {item.imageUrl && !imageError && (
        <div
          className={`relative max-w-2xl cursor-pointer overflow-hidden ${getRounding()}`}
          onClick={handleUrlClick}
        >
          {!imageLoaded && (
            <div className="absolute inset-0 animate-pulse bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200" />
          )}
          <img
            src={`${item.imageUrl}`}
            alt={item.name}
            className={`h-[200px] w-full min-w-[200px] max-w-full rounded-t-lg bg-white object-cover transition-all duration-300 ease-in-out ${
              imageLoaded ? "opacity-100" : "opacity-0"
            }`}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        </div>
      )}
      <div className="flex flex-col gap-1 px-2 pb-2 pt-1">
        <h3 className="line-clamp-2 text-sm font-semibold">{item.name}</h3>
        {item.description && (
          <p className="line-clamp-2 text-xs text-gray-600">{item.description}</p>
        )}
        <a
          href={item.data?.url}
          target="_blank"
          rel="noopener noreferrer"
          className="mt-1 text-xs text-blue-500 hover:underline"
          onClick={(e) => e.stopPropagation()}
        >
          {item.data?.url}
        </a>
      </div>
    </div>
  );
};

export default UrlItem;
