import React from "react";
import { Message } from "../../../../apiClient/generated/models/Message";
import MessageWrapper from "./MessageWrapper";
import MessageContent from "./MessageContent/MessageContent";
import { useDropdown } from "../../hooks/useDropdown";
import { useAuth } from "../../../../routes/(Auth)/AuthProvider";
import DateSeparator from "./Date/DateSeparator";

interface Props {
  messages: Message[];
  onDeleteMessage: (id: string) => void;
}

const MessageList: React.FC<Props> = ({ messages, onDeleteMessage }) => {
  const { userId } = useAuth();
  const { openId, toggle, close } = useDropdown();

  const handleDeleteMessage = (id: string) => {
    onDeleteMessage(id);
    close();
  };

  const groupMessagesByDate = (messages: Message[]) => {
    const groups: { [key: string]: Message[] } = {};

    messages.forEach((message) => {
      const date = new Date(message.time);
      const dateString = date.toDateString();

      if (!groups[dateString]) {
        groups[dateString] = [];
      }
      groups[dateString].push(message);
    });

    return groups;
  };

  const messageGroups = groupMessagesByDate(messages);

  return (
    <>
      {Object.entries(messageGroups).map(([dateString, groupMessages]) => (
        <React.Fragment key={dateString}>
          <div data-date={dateString}>
            <DateSeparator date={new Date(dateString)} />
          </div>
          {groupMessages.map((message: Message) => {
            const isCurrentUser = Boolean(userId && message.sender.id === userId);

            return (
              <MessageWrapper
                key={message.id}
                onDeleteMessage={handleDeleteMessage}
                isDropdownOpen={openId === message.id}
                onToggleDropdown={() => toggle(message.id)}
                message={message}
                isCurrentUser={isCurrentUser}
              >
                <MessageContent message={message} isCurrentUser={isCurrentUser} />
              </MessageWrapper>
            );
          })}
        </React.Fragment>
      ))}
    </>
  );
};

export default MessageList;
