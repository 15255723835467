/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    keywords?: string;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    userCount: number;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    groupCount: number;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    postCount: number;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    pageCount: number;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    eventCount: number;
}

/**
 * Check if a given object implements the Tag interface.
 */
export function instanceOfTag(value: object): value is Tag {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('userCount' in value) || value['userCount'] === undefined) return false;
    if (!('groupCount' in value) || value['groupCount'] === undefined) return false;
    if (!('postCount' in value) || value['postCount'] === undefined) return false;
    if (!('pageCount' in value) || value['pageCount'] === undefined) return false;
    if (!('eventCount' in value) || value['eventCount'] === undefined) return false;
    return true;
}

export function TagFromJSON(json: any): Tag {
    return TagFromJSONTyped(json, false);
}

export function TagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tag {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'imageUrl': json['imageUrl'] == null ? undefined : json['imageUrl'],
        'name': json['name'],
        'description': json['description'],
        'keywords': json['keywords'] == null ? undefined : json['keywords'],
        'userCount': json['userCount'],
        'groupCount': json['groupCount'],
        'postCount': json['postCount'],
        'pageCount': json['pageCount'],
        'eventCount': json['eventCount'],
    };
}

  export function TagToJSON(json: any): Tag {
      return TagToJSONTyped(json, false);
  }

  export function TagToJSONTyped(value?: Tag | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'imageUrl': value['imageUrl'],
        'name': value['name'],
        'description': value['description'],
        'keywords': value['keywords'],
        'userCount': value['userCount'],
        'groupCount': value['groupCount'],
        'postCount': value['postCount'],
        'pageCount': value['pageCount'],
        'eventCount': value['eventCount'],
    };
}

