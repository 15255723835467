import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface MarkdownMessageProps {
  body: string;
  isCurrentUser: boolean;
  className?: string;
}

const MarkdownMessage: React.FC<MarkdownMessageProps> = ({
  body,
  isCurrentUser,
  className = "",
}) => {
  return (
    <div
      className={`text-sm ${isCurrentUser ? "text-[#1A1A1A]" : "text-[#6D6D72]"} ${className}`}
      style={{ whiteSpace: "pre-wrap" }}
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          p: ({ ...props }) => <p className="whitespace-pre-line break-words" {...props} />,
          a: ({ ...props }) => (
            <a
              className="text-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
              {...props}
            />
          ),
          strong: ({ ...props }) => <strong className="font-bold" {...props} />,
          em: ({ ...props }) => <em className="italic" {...props} />,
        }}
      >
        {body}
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownMessage;
