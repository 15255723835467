/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ItemData
 */
export interface ItemData {
    /**
     * 
     * @type {string}
     * @memberof ItemData
     */
    url?: string;
    /**
     * 
     * @type {Date}
     * @memberof ItemData
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItemData
     */
    endTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof ItemData
     */
    badge?: string;
}

/**
 * Check if a given object implements the ItemData interface.
 */
export function instanceOfItemData(value: object): value is ItemData {
    return true;
}

export function ItemDataFromJSON(json: any): ItemData {
    return ItemDataFromJSONTyped(json, false);
}

export function ItemDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemData {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'] == null ? undefined : json['url'],
        'startTime': json['startTime'] == null ? undefined : (new Date(json['startTime'])),
        'endTime': json['endTime'] == null ? undefined : (new Date(json['endTime'])),
        'badge': json['badge'] == null ? undefined : json['badge'],
    };
}

  export function ItemDataToJSON(json: any): ItemData {
      return ItemDataToJSONTyped(json, false);
  }

  export function ItemDataToJSONTyped(value?: ItemData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'url': value['url'],
        'startTime': value['startTime'] == null ? undefined : ((value['startTime']).toISOString()),
        'endTime': value['endTime'] == null ? undefined : ((value['endTime']).toISOString()),
        'badge': value['badge'],
    };
}

