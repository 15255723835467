/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Chat
 */
export interface Chat {
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    type: ChatTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Chat
     */
    memberCount: number;
    /**
     * 
     * @type {number}
     * @memberof Chat
     */
    messageCount: number;
    /**
     * 
     * @type {Date}
     * @memberof Chat
     */
    lastMessageTime: Date;
}


/**
 * @export
 */
export const ChatTypeEnum = {
    Undefined: 'undefined',
    OneOnOne: 'one-on-one',
    GroupPrivate: 'group-private',
    GroupPublic: 'group-public',
    System: 'system'
} as const;
export type ChatTypeEnum = typeof ChatTypeEnum[keyof typeof ChatTypeEnum];


/**
 * Check if a given object implements the Chat interface.
 */
export function instanceOfChat(value: object): value is Chat {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('memberCount' in value) || value['memberCount'] === undefined) return false;
    if (!('messageCount' in value) || value['messageCount'] === undefined) return false;
    if (!('lastMessageTime' in value) || value['lastMessageTime'] === undefined) return false;
    return true;
}

export function ChatFromJSON(json: any): Chat {
    return ChatFromJSONTyped(json, false);
}

export function ChatFromJSONTyped(json: any, ignoreDiscriminator: boolean): Chat {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'imageUrl': json['imageUrl'] == null ? undefined : json['imageUrl'],
        'name': json['name'],
        'description': json['description'],
        'type': json['type'],
        'memberCount': json['memberCount'],
        'messageCount': json['messageCount'],
        'lastMessageTime': (new Date(json['lastMessageTime'])),
    };
}

  export function ChatToJSON(json: any): Chat {
      return ChatToJSONTyped(json, false);
  }

  export function ChatToJSONTyped(value?: Chat | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'imageUrl': value['imageUrl'],
        'name': value['name'],
        'description': value['description'],
        'type': value['type'],
        'memberCount': value['memberCount'],
        'messageCount': value['messageCount'],
        'lastMessageTime': ((value['lastMessageTime']).toISOString()),
    };
}

