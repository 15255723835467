/* tslint:disable */
/* eslint-disable */
/**
 * Mesensei API v5
 * Mesensei API v5
 *
 * The version of the OpenAPI document: 5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    privacy: DocumentPrivacyEnum;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    uploadName: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    mimetype: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    extension: string;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    shortIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentUrl: string;
}


/**
 * @export
 */
export const DocumentPrivacyEnum = {
    Private: 'private',
    Shared: 'shared',
    Public: 'public'
} as const;
export type DocumentPrivacyEnum = typeof DocumentPrivacyEnum[keyof typeof DocumentPrivacyEnum];


/**
 * Check if a given object implements the Document interface.
 */
export function instanceOfDocument(value: object): value is Document {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('privacy' in value) || value['privacy'] === undefined) return false;
    if (!('uploadName' in value) || value['uploadName'] === undefined) return false;
    if (!('mimetype' in value) || value['mimetype'] === undefined) return false;
    if (!('extension' in value) || value['extension'] === undefined) return false;
    if (!('size' in value) || value['size'] === undefined) return false;
    if (!('identifier' in value) || value['identifier'] === undefined) return false;
    if (!('shortIdentifier' in value) || value['shortIdentifier'] === undefined) return false;
    if (!('documentUrl' in value) || value['documentUrl'] === undefined) return false;
    return true;
}

export function DocumentFromJSON(json: any): Document {
    return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Document {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'imageUrl': json['imageUrl'] == null ? undefined : json['imageUrl'],
        'name': json['name'],
        'description': json['description'],
        'privacy': json['privacy'],
        'uploadName': json['uploadName'],
        'mimetype': json['mimetype'],
        'extension': json['extension'],
        'size': json['size'],
        'identifier': json['identifier'],
        'shortIdentifier': json['shortIdentifier'],
        'documentUrl': json['documentUrl'],
    };
}

  export function DocumentToJSON(json: any): Document {
      return DocumentToJSONTyped(json, false);
  }

  export function DocumentToJSONTyped(value?: Document | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'imageUrl': value['imageUrl'],
        'name': value['name'],
        'description': value['description'],
        'privacy': value['privacy'],
        'uploadName': value['uploadName'],
        'mimetype': value['mimetype'],
        'extension': value['extension'],
        'size': value['size'],
        'identifier': value['identifier'],
        'shortIdentifier': value['shortIdentifier'],
        'documentUrl': value['documentUrl'],
    };
}

