import React from "react";

const LoadingMessageList: React.FC = () => {
  return (
    <div className="p-4">
      {[...Array(3)].map((_, groupIndex) => (
        <div key={groupIndex} className="mb-8">
          {/* Date Separator */}
          <div className="my-4 flex items-center justify-center">
            <div className="h-6 w-32 animate-pulse rounded-full bg-gray-200" />
          </div>

          {/* Messages */}
          {[...Array(3)].map((_, messageIndex) => {
            const isRight = messageIndex % 2 === 0;

            return (
              <div
                key={messageIndex}
                className={`mb-4 flex ${isRight ? "justify-end" : "justify-start"}`}
              >
                {/* Avatar (only for left messages) */}
                {!isRight && (
                  <div className="mr-2">
                    <div className="h-8 w-8 animate-pulse rounded-full bg-gray-200" />
                  </div>
                )}

                {/* Message Content */}
                <div
                  className={`flex max-w-[300px] animate-pulse flex-col ${
                    isRight
                      ? "rounded-[13px_13px_0px_13px] bg-gray-200"
                      : "rounded-[13px_13px_13px_0px] bg-gray-100"
                  }`}
                >
                  {/* Sender name (only for left messages) */}
                  {!isRight && <div className="mx-2 mt-2 h-4 w-24 rounded bg-gray-300/50" />}

                  {/* Message body */}
                  <div className="m-2 space-y-2">
                    <div className="h-4 w-48 rounded bg-gray-300/50" />
                    <div className="h-4 w-32 rounded bg-gray-300/50" />
                  </div>

                  {/* Timestamp */}
                  <div className="m-2 ml-auto h-3 w-16 rounded bg-gray-300/50" />
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default LoadingMessageList;
