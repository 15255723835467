import React from "react";
import { FaEllipsisV } from "react-icons/fa";

interface TopBarProps {
  chatName: string;
}

const TopBar: React.FC<TopBarProps> = ({ chatName }) => {
  return (
    <div className="top-0 flex min-h-[50px] items-center justify-end gap-2 bg-[#099e4c] px-4">
      <h2 className="truncate text-sm font-semibold text-white">{chatName}</h2>
      <button className="text-white focus:outline-none">
        <FaEllipsisV size={18} />
      </button>
    </div>
  );
};

export default TopBar;
