import React from "react";

interface CardWideProps {
  image: string;
  heading: string;
  link: string;
}

export default function CardWide({ image, heading, link }: CardWideProps) {
  return (
    <a
      href={link}
      className="flex h-[117px] w-full min-w-[349px] flex-row overflow-hidden rounded-bl-xl rounded-br-none rounded-tl-xl rounded-tr-xl no-underline sm:w-[349px]"
    >
      <img src={image} alt={`${heading} card`} className="h-full w-[139px] object-cover" />
      <h3 className="line-clamp-4 w-full bg-white p-3 text-[17px] font-bold leading-snug sm:w-[210px]">
        {heading}
      </h3>
    </a>
  );
}
