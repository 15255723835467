import React from "react";
import { FileStatus } from "../types";
import FilePreview from "../FilePreview";

interface FilePreviewListProps {
  files: FileStatus[];
  onRemoveFile: (fileId: string) => void;
}

export const FilePreviewList: React.FC<FilePreviewListProps> = ({ files, onRemoveFile }) => {
  return (
    <div className="mb-2 flex flex-wrap gap-2">
      {files.map((file) => (
        <FilePreview key={file.id} file={file} onRemove={() => onRemoveFile(file.id)} />
      ))}
    </div>
  );
};
