import React from "react";
import { Message } from "../../../../apiClient/generated/models/Message";
import { formatDate } from "../../../../utils/dateFormatter";
import MarkdownMessage from "./MessageContent/MarkdownMessage";

interface ItemWrapperProps {
  children: React.ReactNode;
  message: Message;
  isCurrentUser: boolean;
  showSender?: boolean;
  showTimestamp?: boolean;
  showBody?: boolean;
}

const ItemWrapper: React.FC<ItemWrapperProps> = ({
  children,
  message,
  isCurrentUser,
  showSender = true,
  showTimestamp = true,
  showBody = false,
}) => {
  return (
    <div className={`flex items-end ${isCurrentUser ? "justify-end" : "justify-start"}`}>
      <div
        className={`flex max-w-xl flex-col items-start ${
          isCurrentUser
            ? "rounded-[13px_13px_0px_13px] bg-[#DBDDDE]"
            : "rounded-[13px_13px_13px_0px] bg-white"
        } overflow-wrap-anywhere break-words shadow`}
      >
        <div className="relative w-full">
          {!isCurrentUser && showSender && <SenderName name={message.sender.name} />}
          <div className="flex w-full flex-col">
            {children}
            {showBody && message.body && (
              <MarkdownMessage
                body={message.body}
                isCurrentUser={isCurrentUser}
                className="mx-2 mt-2"
              />
            )}
          </div>
          {showTimestamp && <MessageTimestamp time={message.time} isCurrentUser={isCurrentUser} />}
        </div>
      </div>
    </div>
  );
};

const SenderName: React.FC<{ name: string }> = ({ name }) => (
  <p className="m-2 text-sm font-semibold text-blue-500">{name}</p>
);

const MessageTimestamp: React.FC<{ time: Date; isCurrentUser: boolean }> = ({
  time,
  isCurrentUser,
}) => (
  <p
    className={`m-2 justify-end text-right text-xs ${isCurrentUser ? "opacity-70" : "opacity-60"}`}
  >
    {formatDate(new Date(time))}
  </p>
);

export default ItemWrapper;
