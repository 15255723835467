import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

// Set the worker source for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PDFPreviewProps {
  documentUrl: string;
  onClick: () => void;
  isCurrentUser: boolean;
}

const PDFPreview: React.FC<PDFPreviewProps> = ({ documentUrl, onClick, isCurrentUser }) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  const handleLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <div
      className={`relative cursor-pointer overflow-hidden ${
        isCurrentUser ? "rounded-[14px_14px_0px_0px]" : "rounded-[0px_14px_0px_0px]"
      } border`}
      onClick={onClick}
    >
      <Document
        file={documentUrl}
        className="h-[300px] min-w-[200px]"
        onLoadSuccess={handleLoadSuccess}
        loading={
          <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-gray-50">
            <div className="flex flex-col items-center gap-4">
              <div className="h-12 w-12 animate-spin rounded-full border-4 border-gray-200 border-t-[#099e4c]" />
            </div>
          </div>
        }
      >
        {numPages && <Page pageNumber={1} width={200} />}
      </Document>
    </div>
  );
};

export default PDFPreview;
